<template>
  <nav class="navMenu">
    <!--
    <a href="/">Home</a>
    <a href="/about">About</a>
    <a href="#">Contact</a>
    -->
  </nav>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      msg: "Welcome to Lost World",
    };
  },

  methods: {
    hide() {
      console.log("hiding");
      this.$root.$emit("bv::toggle::collapse", "nav-collapse");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navMenu {
  position: absolute;
  top: 10%;
  left: 15%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.navMenu a {
  color: #f6f4e6;
  text-decoration: none;
  font-size: 1.6em;
  text-transform: uppercase;
  font-weight: 500;
  padding: 70px;
  margin: 100 px;
  display: inline-block;
  width: 100px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.navMenu a:hover {
  color: #fddb3a;
}
</style>