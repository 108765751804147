<template>
  <body class="bg"></body>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      msg: 'Welcome to Lost World'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg {
  background-image: url("./../assets/bg.jpg");
  height: 100vh;
  max-height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px;
  padding: 0px;
}
html {
  margin: 0px;
  padding: 0px;
}
</style>